.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .normal {
  width:100%;
  flex:1;
  background:blue;
  margin:15px;
  
}
.normal:hover{
  flex:1.3;
} */
.demo {
  width: 100px;
  /*背景色*/
  background-color: #ccc;
  position: relative;
  border: 4px solid #333;
}
.demo:after, .demo:before {
  border: solid transparent;
  width: 0;
  height: 0;
  /*与左边的距离*/
  left: 100%;
  /*设置:before和:after时必须设置其content属性，否则伪元素就不起作用*/
  content: ' ';
  position: absolute;
}
.demo:after {
  border-width: 19px;
  border-left-color:var(--jiao) ;
  /*到顶部的距离*/
  top: calc(37% + 1px);
}
/*边框厚为14px三角形*/
.demo:before {
  border-width: 20px;
  border-left-color: black;
  /*到顶部的距离*/
  top: 37%;
}
.container {
  position: relative;
   /* 根据需要调整宽高比值（这里为16:9）*/
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
  width: 8px; /* 控制滚动条宽度 */
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* 控制滚动条背景色 */
}

::-webkit-scrollbar-thumb {
  background-color: #aaa; /* 控制滑块（滚动按钮）的颜色 */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #999; /* 当鼠标悬停在滑块上时的颜色 */
}
::-webkit-scrollbar-thumb {
  border-radius: 4px; /* 控制滑块的圆角大小 */
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); /* 控制滑块的阴影效果 */
}